@font-face {
  font-family: 'FilsonPro';
  src: url('/fonts/FilsonPro/FilsonProRegular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'FilsonPro';
  src: url('/fonts/FilsonPro/FilsonProMedium.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'FilsonPro';
  src: url('/fonts/FilsonPro/FilsonProBold.ttf');
  font-style: bold;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'FilsonPro';
  src: url('/fonts/FilsonPro/FilsonProLight.ttf');
  font-style: thin;
  font-weight: 300;
  font-display: swap;
}

html {
  font-size: 16px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
}

body {
  line-height: 'unset';
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  font-family: 'FilsonPro';
}

#__next {
  height: 100%;
}

/* textarea {
  font-family: 'FilsonPro';
} */

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
ul::-webkit-scrollbar,
div::-webkit-scrollbar {
  background-color: transparent;
  width: 9px;
}

/* background of the scrollbar except button or resizer */
ul::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
ul::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #a8a8a8;
  border-radius: 16px;
  border: 2px solid #f3f4fb;
}

ul::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  border: 0px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
ul::-webkit-scrollbar-button,
div::-webkit-scrollbar-button {
  display: none;
}
